import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Router, NavigationEnd } from '@angular/router';
import { LabelName } from '../../../app.constant';

@Component({
  selector: 'tdrs-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  path:string = '';
  showMenu = false;
  public projectReviewName=LabelName.ProjectReview;
  public projectReviewSubtitle=LabelName.ProjectReviewSubtitle;
  

  public get pathName() {
    return window.location.pathname;
  }
  constructor(private readonly router: Router) {
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/login' || (event.url === '/NewTMSUser/registration')
          || (event.url === '/NewPartnerUser/registration')) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      }

    });
  }

  ngOnInit() {
    AOS.init();
    AOS.refresh();
  }

  menuClick(pathName: string) {
    this.router.navigateByUrl(pathName);
  }

}
