import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const NAVIGATE = '[Router] Navigate';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';

export class Navigate implements Action {
  public readonly type = NAVIGATE;
  constructor(
    public payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    }
  ) {}
}

export class Back implements Action {
  public readonly type = BACK;
}

export class Forward implements Action {
  public readonly type = FORWARD;
}

export type RouterActions = Navigate | Back | Forward;
