import { Component, OnInit, Input } from '@angular/core';

//
// Spinner theme
//
declare type Theme = 'dark' | 'light';

//
// Spinner size
//
declare type Size = 'small' | 'medium' | 'large' | 'x-large';
@Component({
  selector: 'dd-spinner',
  templateUrl: './dd-spinner.html',
  styleUrls: ['./dd-spinner.scss']
})
export class ddSpinner implements OnInit {
  @Input() public renderText = true;
  @Input() public theme: Theme;
  @Input() public size: Size;
  public style: object = {};
  constructor() {
    this.renderText = true;
    this.theme = 'dark';
    this.size = 'large';
    this.style = {};
  }

  public ngOnInit() {
    // empty method
  }

}
