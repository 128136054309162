import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ROUTES } from '../../app-routes.constant';
import * as library from '../library/library';

@Injectable({
  providedIn: 'root',
})
export class ChromeGuard implements CanActivate {
  constructor(private readonly _router: Router) {}

  public canActivate(): boolean {
    const allow = library.isChromeBrowser();
    if (!allow) {
      this._router.navigate([ROUTES.NOT_ALLOWED]);
    }
    return allow;
  }
}
