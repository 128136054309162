export const ROUTES = {
  DASHBOARD: '/dashboard',
  NOT_ALLOWED: 'not-allowed',
  INITIATE_REVIEW: 'Reviews/Initiate_Review',
  INITIATE_REVIEW_EDIT: 'Reviews/Initiate_Review?ReviewID=:reviewId',
  FILE_UPLOAD: 'files/upload',
  USER_REGISTRATION: 'NewPartnerUser/registration',
  REVIEWER_REGISTRATION: 'NewTMSUser/registration',
  HELP_CENTER: 'review/helpcenter',
  REVIEW_GUIDELINES: 'review/guidelines',
  MANAGE_REVIEW: 'review/manage-review',
  HOME: 'home',
  REVIEW_OVERVIEW: 'review/overview/:reviewId',
  MY_REVIEW: 'home/myreview',
  SEARCH_REVIEW: 'search/review',
  MY_PROFILE: 'myprofile',
  REVIEW_OVERVIEW_AGENCYUSER: 'agencyuser/:status',
  CONSOLIDATE_COPY: 'Consolidatecopy/:reviewFileId',
  ADD_REMOVE_REVIEWER: 'add_edit/reviewer/review/:reviewId',
  AGENCY_CONTENT_UPLOAD: 'agencyuser-content-upload/:status',
  PUBLISH_REVIEW: 'review/publish/change_deadline/:reviewId',
  ADMIN_USER_MANAGEMENT: 'admin/user',
  ADMIN_USER_EDIT: 'edit/:userId',
  HOLIDAY_LISTS:'holiday-management-lists',
  MANAGE_MODEL:'manage-model',
  ADD_MODEL:'model/add',
  EDIT_MODEL:'model/add?reviewmodelid=:reviewmodelid',
  MANAGE_EDIT_MODEL:'manage-model/model/add?reviewmodelid=:reviewmodelid'
};
