import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TdrsHttpEnvironmentService } from '../environment';
import { tdrsHttpService } from '../common/tdrs-http.service';
import { replaceKeyValueWithString } from '../library/library';

@Injectable()
export class StaticContentService extends tdrsHttpService{
  public readonly legalGuidlinesAPI: string = 'api/v1/review/guides/LegalGuidelines';
  public readonly currentDisclaimersAPI: string = 'api/v1/review/guides/CurrentDisclaimersList';
  public readonly photoVideoGuidelinesAPI = 'api/v1/review/guides/PhotoVideoGuidelines';
  public readonly helpCenterAPI: string = 'api/v1/review/guides/FAQs';
  public readonly updateDisclaimersAPI: string = 'api/v1/review/guides/UpdateCurrentDisclaimer/:uploadStatus?origFileName=:origFileName&disclaimerDate=:disclaimerDate';

  constructor(protected httpClient: HttpClient,
    public tdrsHttpEnvironmentService: TdrsHttpEnvironmentService) {
    super(httpClient, tdrsHttpEnvironmentService)
  }

  /**
   * Function to get the Legal guidlines list
   */
  getLegalGuidelinesData(): Observable<HttpResponse<any>> {
    const url = this.legalGuidlinesAPI;
    return this.get(url, null, null, null, { observe: 'response' });
  }

  /**
   * Function to get the Disclaimer Data
   */
  getDisclaimerData(): Observable<HttpResponse<any>> {
    const url = this.currentDisclaimersAPI;
    return this.get(url, null, null, null, { observe: 'response' });
  }

  /**
   * Function to get the Disclaimer Data
   */
  getS3SignedUrlForFile(disclaimerFile, uploadStatus): Observable<HttpResponse<any>> {    
    const url = replaceKeyValueWithString(this.updateDisclaimersAPI, {
      ':origFileName': disclaimerFile,
      ':uploadStatus': uploadStatus
    });
    // Get S3 signed url from service
    return this.get(url, null, null, null);
  }

  uploadDisclaimerFileWithSignedUrl(fileData, signedUrl): Observable<any> {
    const headerDict = {
      'Content-Length': fileData.size,
      'Content-Type': fileData.type
    };
    const requestOptions = {      
      headers: new Headers(headerDict), 
    };    
    return this.put(signedUrl, fileData, null, 'asset', requestOptions);    
  }

  updateDisclaimerContent(filename,uploadStatus,disclaimerDate): Observable<any> {
    const url = replaceKeyValueWithString(this.updateDisclaimersAPI, {
      ':disclaimerDate': disclaimerDate,
      ':origFileName' : filename,
      ':uploadStatus': uploadStatus
    });
    return this.get(url, null, null, null);
  }

  /**
   * Function to get the Photo & Video Guidelines Data
   */
  getPhotoVideoGuidelinesData(): Observable<HttpResponse<any>> {
    const url = this.photoVideoGuidelinesAPI;
    return this.get(url, null, null, null, { observe: 'response' });
  }

  /**
   * Function to get the help center Data
   */
  getHelpCenterData(): Observable<HttpResponse<any>>{
    const url = this.helpCenterAPI;
    return this.get(url, null, null, null, { observe: 'response' });
  }


  downLoadFiles(url): Observable<HttpResponse<any>> {
    return this.get(url, null, 'asset', null, {responseType: 'blob',  observe: 'response'});
  }
}
