import { Action } from '@ngrx/store';
//
// Actions
//
import {
  APP_NAVBAR_ACTION,
  AppNavBarSetNavigation
} from '../actions/app-navbar.action';
//
// Reducer Interface
//
export interface IAppNavBarState {
  avatar: any;
  menu: any;
  back: any;
  notification: any;
  title: any;
}
//
// Initial State
//
export const IAPP_NAVBAR_INITIAL_STATE: IAppNavBarState = {
  avatar: {},
  menu: {},
  back: {},
  notification: {},
  title: {}
};
/* tslint:disable:no-switch-case-fall-through */
export function AppNavBarStateReducer(
  state: IAppNavBarState = IAPP_NAVBAR_INITIAL_STATE,
  action: Action
): IAppNavBarState {

  //
  // Switch on Action Type
  //
  switch (action.type) {
    case APP_NAVBAR_ACTION.SET_NAVIGATION:
      return _setNavigation(state, action as AppNavBarSetNavigation);
    default: {
      return state;
    }
  }
}

//
// _setHidden()
//
function _setNavigation(
  state: IAppNavBarState,
  action: AppNavBarSetNavigation
): IAppNavBarState {
  return {...state, ...action.payload};
}
//
// General Operations
//
export const getAppNavBar = (state: IAppNavBarState) => state;
