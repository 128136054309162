import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';
import { SharedDataService } from '../../../service/common/shared-data.service';

@Component({
  selector: 'dd-shared-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() public title: string;
  @Input() public contentBody: string;
  @Input() public screenName: string;
  @Input() public logoSrc: any;
  // private title = 'Supercharge your customer experience';
  // private contentBody = 'Create a seamless customer centric shopping experience both online and instore';
  // private img_Toyota_Logo_path = require("./Toyota_Logo.png");

  constructor(private readonly router: Router,
    private readonly sharedDataService: SharedDataService) { }

  ngOnInit() {
    AOS.init();
    AOS.refresh();
  }

  public getStarted() {
    if (this.screenName === 'SmartPath') {
      this.sharedDataService.pricingOption.type = 'SmartPath';
    }
    else {
      this.sharedDataService.pricingOption.type = 'Dealer Daily';
    }
    this.router.navigate(['/GetInTouch']);
  }

}
