import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { tdrsBusService } from './bus/tdrs-bus.service';
import { tdrsErrorService } from './common/tdrs-error.service';
import { tdrsHttpService } from './common/tdrs-http.service';
import { tdrsLoggerService } from './common/tdrs-logger.service';
import { tdrsNotificationService } from './common/tdrs-notification.service';
import { tdrsSpinnerService } from './common/tdrs-spinner.service';
import { ChromeGuard } from './guard/chrome.guard';
import { ModalService } from './site/modal.service';

const DD_PROVIDERS = [
  tdrsBusService,
  tdrsErrorService,
  tdrsHttpService,
  tdrsLoggerService,
  tdrsNotificationService,
  tdrsSpinnerService,
  ChromeGuard,
  ModalService
];

@NgModule({
  imports: [HttpClientModule],
  providers: DD_PROVIDERS,
  declarations: [],
})
export class tdrsServiceModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: tdrsServiceModule,
      providers: DD_PROVIDERS,
    };
  }
}
