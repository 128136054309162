import { InjectionToken } from '@angular/core';
import {
  IEnvironment
} from '../../model/common/environment';

export interface ItdrsHttpEnvironmentSettings extends IEnvironment {};
export interface TdrsHttpEnvironmentConfig extends Array<any> {}
export const deafultTmHttpEnvironmentConfig: TdrsHttpEnvironmentConfig = [];

export const TDRS_HTTP_ENVIRONMENT_CONFIG = new InjectionToken<
TdrsHttpEnvironmentConfig
>('tdrs-http-environment-config');
