import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EventType } from '@azure/msal-browser';
import { IGraphProfile, UserGroupMap, UserLoggedInInfo, UserRoles } from '../../../model/engage/user-detail';
import { TdrsHttpEnvironmentService } from '../../../service/environment';
import _ from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { BRAND } from '../../../app.constant';
import { MsalService } from '../../../msal';
import { PROFILE_GRAPH_API } from '../../../msal/constants';
import { tdrsHttpService } from '../tdrs-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService extends tdrsHttpService {
  public loggedInUserDetailData: UserLoggedInInfo = new UserLoggedInInfo({} as any);
  public readonly getUserDetailAPI : string = 'api/v1/review/myProfile';
  public authService: MsalService;

  protected loggedInUserDetails: BehaviorSubject<any> = new BehaviorSubject({});
  public token: any = {};

  constructor(public injector: Injector, protected httpclient: HttpClient,
    public tdrsHttpEnvironmentService: TdrsHttpEnvironmentService) {
    super(httpclient, tdrsHttpEnvironmentService);
    this.authService = injector.get(MsalService);
    this.getAuthDetails();
  }

  setToken(result) {
    if (result.eventType === EventType.LOGIN_SUCCESS) {
      this.token = result.payload;
      this.setUserDetails(this.token);
    }
  }

  getAuthDetails() {
    const scopes = ['user.read'];
    const account = this.authService.getAllAccounts()[0];
    if (account) {
      this.authService.acquireTokenSilent({ scopes, account }).subscribe(res => {
        this.token = res;
        this.setUserDetails(this.token);
      });
    }
  }

  /**
   * Function to set the User details from Token and API
   * @param token 
   */
  setUserDetails(token) {
    this.loggedInUserDetailData = {
      username: _.get(token, 'account.username', ''),
      name: _.get(token, 'idTokenClaims.name', ''),
      groups: this.mapUserRoles(_.get(token, 'idTokenClaims.roles', [])),
      roles: _.get(token, 'idTokenClaims.roles', []),
      workdayId: _.get(token, 'idTokenClaims.workdayId', ''),
      brand: [],
      userid: '',
      conceptusergroup: '',
      email: '',
      firstName: '',
      lastName: ''
    };
    if (token?.accessToken) {
      this.getGraphProfile().subscribe((profile: IGraphProfile) => {
        if (profile?.mail) {
          this.loggedInUserDetailData.email = profile.mail.replace('tmnatest.com', 'toyota.com');
          this.mapUserDetails(this.loggedInUserDetailData.email);
        }
      });
    }
  }

  /**
   * Map the user details info from API
   * @param userMail Logged in user Email
   */
  mapUserDetails(userMail) {
    if (userMail) {
      this.getUserProfileInfo({email: userMail}).subscribe((res) => {
        const userid = _.get(res, 'data.userDetails.0.userid', '');
        const brand = [];
        if (userid) {
          this.loggedInUserDetailData.userid = userid;
        }
        if (_.get(res, 'data.userDetails.0.conceptbrandtoyota', '')) {
          brand.push(BRAND.Toyota);
        }
        if (_.get(res, 'data.userDetails.0.conceptbrandlexus', '')) {
          brand.push(BRAND.Lexus);
        }
        this.loggedInUserDetailData.conceptusergroup = _.get(res, 'data.userDetails.0.conceptusergroup', '');
        this.loggedInUserDetailData.brand = brand;
        const firstName = _.get(res, 'data.userDetails.0.firstname', '');
        const lastName = _.get(res, 'data.userDetails.0.lastname', '');
        this.loggedInUserDetailData.firstName = firstName;
        this.loggedInUserDetailData.lastName = lastName;
        this.loggedInUserDetailData.name = `${firstName} ${lastName}`;
        this.loggedInUserDetails.next(this.loggedInUserDetailData);
      }, () => {
        this.loggedInUserDetails.next(this.loggedInUserDetailData);
      });
    }
  }

  /**
   * Function to map the user roles with user group name.
   */
  mapUserRoles(roles: Array<any>) {
    roles = roles.map((x) => {
      return UserGroupMap.get(x.split('-')[0]);
    });
    return roles;
  }

  /**
   * Function to check User is Super admin
   */
  public isSuperAdmin() {
    const isAuthorizedAdmin = [
      this.loggedInUserDetailData.groups.some(x => x.includes(UserRoles.SuperUser)),
      this.loggedInUserDetailData.conceptusergroup ? true : false,
      this.loggedInUserDetailData.brand.length > 0
    ];
    return isAuthorizedAdmin.every((x) => x);
  }

  /**
   * Function to check User is Agency/Graphics parter
   */
  public isAgencyUser() {
    return this.loggedInUserDetailData.groups.some(x => x.includes(UserRoles.Agency_GraphicsPartner));
  }

  /**
   * Function to subscribe the details for current loggedin user
   */
  public getUserDetails(): Observable<UserLoggedInInfo> {
    return this.loggedInUserDetails;
  }

  /**
   * Check user is authorized.
   */
  public isAuthorized() {
    return this.authService.getAllAccounts().length > 0;
  }

  public isActiveUser() {
    return this.isBrandAssigned() && this.loggedInUserDetailData.conceptusergroup;
  }

  /**
   * Function to check is brand assigned for user.
   */
  public isBrandAssigned() {
    return this.loggedInUserDetailData?.brand?.length > 0;
  }

  /**
   * Function to get the User details from API
   * @param options User email
   */
  public getUserProfileInfo(options): Observable<any> {
    const body = options;
    return this.post(this.getUserDetailAPI, body, null, null, {});
  }

  /**
   * Function to get the profile information from Azure AD
   */
  public getGraphProfile(): Observable<any> {
    return this.httpclient.get(PROFILE_GRAPH_API);
  }
}
