export class Endpoint {
  public get headers(): Array<{ key: string; value: string }> {
    return [...this._headers];
  }

  public set headers(headers: Array<{ key: string; value: string }>) {
    if (headers && Array.isArray(headers) && headers.length > 0) {
      this._headers = [...headers];
    } else {
      this._headers = [];
    }
  }
  public port: string;
  public secure: boolean;
  public suffix: string;
  public type: any;
  public url: string;
  public requireToken: boolean;

  private _headers: Array<{ key: string; value: string }>;

  constructor(options?: Partial<Endpoint>) {
    this.port = options.port;
    this.secure = options.secure ? true : false;
    this.suffix = options.suffix;
    this.type = options.type;
    this.url = options.url || 'localhost';
    this.requireToken = options.requireToken ? true : false;
    this.headers = options.headers || [];
  }
}

export class DISEndpoint extends Endpoint {
  url: string;
  apiKey: string;
  appKey: string;
  constructor(options?: Partial<DISEndpoint>) {
    super(options);
    this.apiKey = options.apiKey;
    this.appKey = options.appKey;
  }
}

export class LoginRedirectEndpoint extends Endpoint {
  url: string;
  clientId: string;
  redirectUri: string;
  postLogoutRedirectUri: string
  constructor(options?: Partial<LoginRedirectEndpoint>) {
    super(options);
    this.clientId = options.clientId;
    this.redirectUri = options.redirectUri;
    this.postLogoutRedirectUri = options.postLogoutRedirectUri;
  }
}

export class FileUploadEndpoint extends Endpoint {
  url: string;
  constructor(options?: Partial<FileUploadEndpoint>) {
    super(options);
  }
}
