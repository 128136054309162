import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TdrsHttpEnvironmentService } from '../environment';

@Injectable()
export class EmailService {
    public defaultHeaders: Headers = new Headers();

    constructor(private readonly http: Http, protected httpClient: HttpClient,
        private readonly tdrsHttpEnvironmentService: TdrsHttpEnvironmentService) { }

    public sendEmail(message: any): Observable<any> {
        const path = this.tdrsHttpEnvironmentService.api.url + '/api/sendemail';
        const headerParams = this.defaultHeaders;
        headerParams.set('Content-Type', 'application/json');

        const requestOptions: RequestOptionsArgs = {
            method: 'POST',
            headers: headerParams,
            withCredentials: true
        };

        return this.http.post(path, message, requestOptions)
            .pipe(
                // catchError
                map((response: Response) => {
                    return response.json();
                })
            );
    }
}
