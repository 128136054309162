import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TdrsHttpEnvironmentService } from '../../service/environment';
import { Observable } from 'rxjs';
import { tdrsHttpService } from './tdrs-http.service';

@Injectable({
  providedIn: 'root'
})
export class NeedHelpService extends tdrsHttpService {

  public readonly needHelpPostAPI: string = 'api/v1/review/needHelp';

  constructor(protected httpClient: HttpClient,
    public tdrsHttpEnvironmentService: TdrsHttpEnvironmentService) {
    super(httpClient, tdrsHttpEnvironmentService)
  }

  postNeedHelp(options: any): Observable<any> {
    const body = options.body;
    return this.post(this.needHelpPostAPI, body, null, null, {});
  }


}
