import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, Route } from '@angular/router';
import { RouteMap, UserLoggedInInfo } from '@model/engage/user-detail';
import { UserDetailService } from '@services/common/user/user-detail.service';
import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ROUTES } from 'src/app/app-routes.constant';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(public router: Router,
    public userDetailService: UserDetailService) { }

  canLoad(route: Route): Observable<any> {
    const path: [] = RouteMap.get(route.path);
    if (this.userDetailService.authService.getAllAccounts().length === 0) {
      this.router.navigateByUrl(ROUTES.HOME);
      return of(false);
    }
    return this.userDetailService.getUserDetails().pipe(filter((x: UserLoggedInInfo) => {
      return x?.groups?.length > 0
    }), map((res: UserLoggedInInfo) => {
      const isAuthorized = [
        res?.groups && path.some((x) => res.groups.some((y) => y.includes(x))),
        res?.conceptusergroup ? true : false,
        res?.brand.length > 0
      ];
      if (isAuthorized.every(x => x === true)) {
        return true
      }
      this.router.navigateByUrl(ROUTES.NOT_ALLOWED);
      return false
    }));
  }

  canActivate() {
    return false;
  }

  canActivateChild() {
    return true;
  }

  timeDiff() {
    if (localStorage.getItem('token')) {
      const dateOne = localStorage.getItem('token') || new Date().toString();
      const dateTwo = new Date().toString();
      const dateOneObj: any = new Date(dateOne);
      const dateTwoObj: any = new Date(dateTwo);
      const milliseconds = (dateTwoObj - dateOneObj);
      const hours = milliseconds / 36e5;
      return hours < 8
    }
    return false
  }

}