import { ActionReducer, MetaReducer, ActionReducerMap } from '@ngrx/store';

import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from './router.reducer';

import { AppMenuStateReducer } from './app-menu.reducer';
import { AppNavBarStateReducer, IAppNavBarState } from './app-navbar.reducer';
import { IAppMenuState } from '../../store/reducers/app-menu.reducer';

export interface IState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  menu: IAppMenuState;
  navbar: IAppNavBarState;
  //dealerDetail: DealerDetail;
}

export const reducers: ActionReducerMap<IState> = {
  router: fromRouter.routerReducer,
  menu: AppMenuStateReducer,
  navbar: AppNavBarStateReducer,
  //dealerDetail: DealerDetailStateReducer,
};

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any>> = [];

export * from './router.reducer';
