import { enableProdMode, ReflectiveInjector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { tdrsLoggerService } from './app/service/common/tdrs-logger.service';
import { tdrsErrorService } from './app/service/common/tdrs-error.service';
import {
  TdrsHttpEnvironmentService,
  TDRS_HTTP_ENVIRONMENT_CONFIG
} from './app/service/environment';

// Environments
//
import * as environments from './json/tdrs-environment.json';

const injector = ReflectiveInjector.resolveAndCreate([
  tdrsLoggerService,
  tdrsErrorService,
  {
    provide: TDRS_HTTP_ENVIRONMENT_CONFIG,
    useValue: JSON.parse(JSON.stringify(environments['default']))
  },
  TdrsHttpEnvironmentService
]);
const loggerService: tdrsLoggerService = injector.get(tdrsLoggerService);
const errorService: tdrsErrorService = injector.get(tdrsErrorService);
const environmentSerivce: TdrsHttpEnvironmentService = injector.get(TdrsHttpEnvironmentService);

if (environmentSerivce.getTag('app-dynamics') === true) {
  // Below script is to enable the App Dynamic
  const appDynamicScript =
    `window['adrum-start-time'] = new Date().getTime();
  (function(config){
    config.appKey = '` +
      environmentSerivce.getTag('dynamic') +
    `';
    config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
    config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
    config.beaconUrlHttp = 'http://col.eum-appdynamics.com';
    config.beaconUrlHttps = 'https://col.eum-appdynamics.com';
    config.xd = {enable : false};
})(window['adrum-config'] || (window['adrum-config'] = {}));`;

  const script = document.createElement('script');
  script.charset = 'UTF-8';
  script.innerHTML = appDynamicScript;

  const scriptAppD = document.createElement('script');
  scriptAppD.charset = 'UTF-8';
  scriptAppD.src = '//cdn.appdynamics.com/adrum/adrum-4.5.5.1807.js';

  // it's important to append the app dynamics script to the header.
  document.getElementsByTagName('head')[0].appendChild(script);
  document.getElementsByTagName('head')[0].appendChild(scriptAppD);
}

if (environmentSerivce.name === 'Production') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => loggerService.error(errorService.getClientErrorMessage(err)));
