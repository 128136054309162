import { Action } from '@ngrx/store';
import { IAppNavBarState } from '../reducers/app-navbar.reducer';

/**
 * NavBar Action Types
 */
export const APP_NAVBAR_ACTION = {
  LOAD: '[App] APP_NAVBAR_LOAD',
  SET_STYLE: '[App] APP_NAVBAR_STYLE',
  SET_HIDDEN: '[App] APP_NAVBAR_HIDDEN',
  OPEN_QUICKVIEW: '[App] APP_NAVBAR_OPEN_QUICKVIEW',
  CLOSE_QUICKVIEW: '[App] APP_NAVBAR_CLOSE_QUICKVIEW',
  PARTIAL_QUICKVIEW: '[App] APP_NAVBAR_PARTIAL_QUICKVIEW',
  SET_NAVIGATION: '[App] APP_NAVBAR_NAVIGATION'
};

/**
 * AppNavBarSetNavigation
 */
export class AppNavBarSetNavigation implements Action {
  public readonly type: string = APP_NAVBAR_ACTION.SET_NAVIGATION;
  constructor(public payload?: Partial<IAppNavBarState>) {}
}

export type NavbarActions = AppNavBarSetNavigation;
