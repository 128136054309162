import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class httpSessionUpdateInterceptor implements HttpInterceptor {

  readonly DOMAIN = '.toyota.com';
  readonly COOKIE_NAME = 'DDSessionTimer';
  readonly PATH = '/';


  constructor() {

  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const currentDate = new Date();

    // should be of format 'M/D/YYYY HH:MM:SS
    const dateString = (1+currentDate.getUTCMonth()) + '/' +
                        currentDate.getUTCDate() + '/' +
                        currentDate.getUTCFullYear() + ' ' +
                        this.padStr(currentDate.getUTCHours()) + ':' +
                        this.padStr(currentDate.getUTCMinutes()) + ':' +
                        this.padStr(currentDate.getUTCSeconds());

    document.cookie = `${this.COOKIE_NAME}=${dateString}; domain=${this.DOMAIN}; path=${this.PATH};`;

    return next.handle(request);
  }

  private padStr(i) {
    return (i < 10) ? '0' + i : '' + i;
  }
}
