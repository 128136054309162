import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tdrsHttpService } from '../../common/tdrs-http.service';
import { TdrsHttpEnvironmentService } from '../../environment';
import { Observable } from 'rxjs';
import { replaceKeyValueWithString } from '../../../service/library/library';

@Injectable({
  providedIn: 'root'
})

export class AddEditLegalReviewService extends tdrsHttpService {

  public readonly legalReviewgetAPI: string = 'api/v1/review/legalReviewers/:reviewId/:brand';
  public readonly legalReviewpostAPI: string = 'api/v1/review/legalReviewers';


  constructor(protected httpclient: HttpClient,
    public tdrsHttpEnvironmentService: TdrsHttpEnvironmentService) {
    super(httpclient, tdrsHttpEnvironmentService)
  }

  getLegalReviewDetail(options: any): Observable<any> {
    const url = replaceKeyValueWithString(this.legalReviewgetAPI, {
      ':reviewId': options.reviewId,
      ':brand': options.brand
    });
    return this.get(url, null, null, null, { observe: 'response' });
  }

  postLegalReviewDetail(options: any): Observable<any> {
    const body = options.body;
    return this.post(this.legalReviewpostAPI, body, null, null, {});
  }

}

