import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';

import  { default as environments }  from '../../json/tdrs-environment.json';
import { PROFILE_GRAPH_API } from './constants';
import { MsalInterceptorConfig } from './msal.interceptor.config';

export function MSALInstanceFactory(): IPublicClientApplication {
  const env = environments;
  const hostname = window.location.hostname.toLowerCase();
  const envIdx = env.findIndex(e => e.hostname.url.toLowerCase() === hostname);
  let oAuth;
  if (envIdx > -1) {
    oAuth = env[envIdx].endpoints.loginredirect;
  }
  return new PublicClientApplication({
    auth: {
      clientId: oAuth['clientId'],
      authority: oAuth.url,
      redirectUri: oAuth.redirectUri || window.location.href
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(PROFILE_GRAPH_API, ['user.read']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}
