import { NgModule, ModuleWithProviders } from '@angular/core';
/**
 * Services
 */
import { TdrsHttpEnvironmentService } from './tdrs-environment.service';
import {
    TdrsHttpEnvironmentConfig,
    TDRS_HTTP_ENVIRONMENT_CONFIG
} from './tdrs-environment.provider';

@NgModule({
  imports: [],
  providers: [TdrsHttpEnvironmentService]
})
export class TdrsHttpServiceModule {
  /**
   * forRoot()
   */
  public static forRoot(config: TdrsHttpEnvironmentConfig = []): ModuleWithProviders<any> {
    return {
      ngModule: TdrsHttpServiceModule,
      providers: [
        TdrsHttpEnvironmentService,
        { provide: TDRS_HTTP_ENVIRONMENT_CONFIG, useValue: config['default'] }
      ]
    };
  }
}
