import { Action } from '@ngrx/store';

/**
 * Menu Action Types
 */
export const APP_MENU_ACTION = {
  LOAD: '[App] APP_MENU_LOAD',
  SET_ACTIVE: '[App] APP_MENU_SET_ACTIVE',
  ON_CHANGE: '[App] APP_MENU_ON_CHANGE'
};

/**
 * AppMenuSetActive
 */
export class AppMenuSetActive implements Action {
  public readonly type: string = APP_MENU_ACTION.SET_ACTIVE;
  constructor(public payload?: any) {}
}
/**
 * AppMenuOnChange
 */
export class AppMenuOnChange implements Action {
  public readonly type: string = APP_MENU_ACTION.ON_CHANGE;
  constructor(public payload?: any) {}
}

export type MenuActions = AppMenuSetActive | AppMenuOnChange;
