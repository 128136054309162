import { createSelector } from '@ngrx/store';
import { IState } from '../reducers';
import { getAppMenu } from '../reducers/app-menu.reducer';
import { getAppNavBar } from '../reducers/app-navbar.reducer';

//
// Router Selector
//
// export const selectRouter = createSelector(getRouterState);
//
// Navbar Selector
//
export const selectNavbar = createSelector(
  (state: IState) => {
    return state.navbar;
  },
  getAppNavBar
);

//
// Menu Selector
//
export const selectMenu = createSelector(
  (state: IState) => {
    return state.menu;
  },
  getAppMenu
);
