import { Action } from '@ngrx/store';
//
// Actions
//
import {
  APP_MENU_ACTION,
  AppMenuSetActive
} from '../actions/app-menu.action';
//
// Reducer Interface
//
export interface IAppMenuState {
  active?: any;
}
//
// Initial State
//
export const IAPP_MENU_INITIAL_STATE: IAppMenuState = {
  active: undefined
};
/* tslint:disable:no-switch-case-fall-through */
export function AppMenuStateReducer(
  state: IAppMenuState = IAPP_MENU_INITIAL_STATE,
  action: Action
): IAppMenuState {
  //
  // Switch on Action Type
  //
  switch (action.type) {
    case APP_MENU_ACTION.SET_ACTIVE:
      return _onSetActive(state, action as AppMenuSetActive);
    default: {
      return state;
    }
  }
}

//
// _onSetActive()
//
function _onSetActive(
  state: IAppMenuState,
  action: AppMenuSetActive
): IAppMenuState {
  return {...state,  active: action.payload};
}

//
// General Operations
//
export const getAppMenu = (state: IAppMenuState) => state;
