import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class tdrsBusService {
  private readonly _bus: Subject<any> = new Subject<any>();

  constructor() {
  }
  /**
   * Dispat
   * ch()
   */
  public dispatch(message: any) {
    if (!isNil(message)) {
      this._bus.next(message);
    }
  }
  /**
   * message()
   */
  public message(): Observable<any> {
    return this._bus.asObservable();
  }
}
