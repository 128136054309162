import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from '../actions/router.action';

@Injectable()
export class RouterEffects {
  @Effect({ dispatch: false })
  public navigate$ = this.actions$.pipe(
    ofType(RouterActions.NAVIGATE),
    map((action: RouterActions.Navigate) => action.payload),
    tap(({ path, query: queryParams, extras }) => {
      this.router.navigate(path, { queryParams, ...extras });
    })
  );

  @Effect({ dispatch: false })
  public navigateBack$ = this.actions$
    .pipe(
      ofType(RouterActions.BACK),
      tap(() => this.location.back())
    );

  @Effect({ dispatch: false })
  public navigateForward$ = this.actions$
    .pipe(
      ofType(RouterActions.FORWARD),
      tap(() => this.location.forward())
    );
    
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly location: Location
  ) {}

}
