import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class tdrsLoggerService {
    public log(message: any) {
        // tslint:disable-next-line:no-console
        console.log('ddLoggerService: ' + message);
    }
    public error(message: any) {
        console.error('ddLoggerService: ' + message);
    }
}
