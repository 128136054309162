import { Injectable } from '@angular/core';

import { Effect, Actions, ofType } from '@ngrx/effects';
import * as AuthorizationActions from '../actions/authorization.action';

import { map } from 'rxjs/operators';

@Injectable()
export class AuthorizationEffects {
  @Effect({ dispatch: false })
  public navigate$ = this.actions$.pipe(
    ofType(AuthorizationActions.USER_SIGNED_IN),
    map((): void => {
    })
  );
    
  constructor(
    private readonly actions$: Actions,
  ) {}

}
