import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { tabs } from './app.constant';
import * as AOS from 'aos';
import { MsalService, MsalBroadcastService } from './msal';
import { MSAL_GUARD_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { NeedHelpComponent } from './feature/need-help/need-help.component';
import { MatDialog } from '@angular/material/dialog';

import { UserDetailService } from '../app/service/common/user/user-detail.service';
import { Router } from '@angular/router';




@Component({
  selector: 'tgpp-app',
  styleUrls: [
    './app.component.scss',
  ],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  public navigationTabs: any[] = [tabs.home, tabs.dealerdaily, tabs.pricing, tabs.faq, tabs.get_in_touch];
  public activedTab: string;
  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  // Constructor for app component
  constructor(public location: Location,
    public router:Router,
    public userDetailService: UserDetailService,
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService, public dialog: MatDialog) {
    this.activedTab = this.location.path().substr(1);
  }

  ngOnInit() {
    AOS.init({
      duration: 500,
      delay: 500
    });
    AOS.refresh();
    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.HANDLE_REDIRECT_END),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        if (result.eventType === EventType.LOGIN_SUCCESS
          || result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          this.checkAccount();
        }
        if (result.eventType === EventType.LOGIN_SUCCESS) {
          this.userDetailService.setToken(result);
        }
      });
  }

  checkAccount() {
    this.loggedIn = this.authService.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest })
        .subscribe(() => this.checkAccount());
    } else {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest });
    }
  }

  openDialog(): void {
    this.dialog.open(NeedHelpComponent, {
      autoFocus: false,
    });
  }


  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }



  public navbarClick(event, tab) {
    this.activedTab = tab;
  }

}
