import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { TdrsHttpEnvironmentService } from '../../service/environment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MsalService } from '../../msal';
import { MSAL_INTERCEPTOR_CONFIG } from '../../msal/constants';
import { MsalInterceptorConfig } from '../../msal/msal.interceptor.config';

@Injectable()
export class HttpAPIInterceptor implements HttpInterceptor {
  constructor(
    public environmentService: TdrsHttpEnvironmentService,
    public msalService: MsalService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) public msalInterceptorConfig: MsalInterceptorConfig
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userscopes = ['user.read'];
    const useraccount = this.msalService.getAllAccounts()[0];
    if ((!userscopes || userscopes.length === 0)) {
      return next.handle(req);
    }

    // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
    return this.msalService.acquireTokenSilent({ scopes:userscopes, account:useraccount })
      .pipe(
        catchError(() => {
          if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
            return this.msalService.acquireTokenPopup({ ...this.msalInterceptorConfig.authRequest, scopes:userscopes });
          }
          const redirectStartPage = window.location.href;
          this.msalService.acquireTokenRedirect({ ...this.msalInterceptorConfig.authRequest, scopes:userscopes, redirectStartPage });
          return EMPTY;
        }),
        switchMap((result: AuthenticationResult) => {
          const envURL = new URL(req.url);
          if (this.environmentService.asset.url === envURL.hostname|| this.environmentService.api.url === envURL.hostname || this.environmentService?.fileuploadapi?.url === envURL.hostname) {
            const reqClone = this.setHeaders(req, result);
            return next.handle(reqClone);
          }
          return next.handle(req);
        })
      );
  }

  setHeaders(req,result){
    let headers = req.headers.set('authorization', `Bearer ${result.idToken}`);
    headers = headers.set('accesstoken', `Bearer ${result.accessToken}`);
    return req.clone({ headers });

  }
}


