import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class tdrsSpinnerService {
  protected _spinner: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor() {}

  public getSpinner$(): Observable<boolean> {
    return this._spinner;
  }
  public showSpinner() {
    this._spinner.next(true);
  }
  public hideSpinner() {
    this._spinner.next(false);
  }
}
