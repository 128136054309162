import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { tdrsBusService} from '../bus/tdrs-bus.service';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    private readonly _openActions: Subject<any>;
    private readonly _closeActions: Subject<any>;

    public get openModalActions$(): Observable<any> {
        return this._openActions.pipe();
    }

    public get closeModalActions$(): Observable<any> {
        return this._closeActions.pipe();
    }

    constructor(
      private readonly _messageService : tdrsBusService
    ) {
        this._openActions = new Subject();
        this._closeActions = new Subject();
        this._messageService.message().subscribe(msg=>{
          if(msg.action === 'close') {
            this._closeActions.next();
          }
        })
    }

    public dispatchOpenModal(payload: any): void {
        this._openActions.next(payload);
    }
    public dispatchCloseModal(): void {
        this._closeActions.next();
    }
};
