import { RouterEffects } from './router.effect';
import { AuthorizationEffects } from './authorization.effect';

export const effects: any[] = [
  RouterEffects,
  AuthorizationEffects
];

export * from './router.effect';
export * from './authorization.effect';
