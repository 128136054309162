import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorizedDirective } from './authorized.directive';



@NgModule({
  declarations: [AuthorizedDirective],
  imports: [
    CommonModule
  ],
  exports: [AuthorizedDirective]
})
export class DirectivesModule { }
