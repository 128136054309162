import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ddSpinner } from './dd-spinner';
@NgModule({
  declarations: [ddSpinner],
  exports: [ddSpinner],
  imports: [
    CommonModule
  ]
})
export class ddSpinnerModule { }
