import { Endpoint, DISEndpoint, LoginRedirectEndpoint, FileUploadEndpoint } from './endpoint';
import { isNil, isEmpty } from 'lodash-es';

//
//  interface: IEnvironment
//
export interface IEnvironment {
  name: string;
  hostname: Endpoint;
  api: Endpoint;
  asset: Endpoint;
  dis: DISEndpoint;
  engageUserDetail: Endpoint;
  video: Endpoint;
  loginredirect: LoginRedirectEndpoint;
  getTag(key: string): string | boolean;
}

//
//  class: Environment
//
//  description: The purpose of this class is to hold (n) number of endpoints
//  and per hosted environment in addition authentication per hosted environment
//  is percisted.
//
export class Environment implements IEnvironment {

  name: string;
  hostname: Endpoint;
  private readonly _endpoints: Map<string, Endpoint>;
  private readonly _disendpoints: Map<string, DISEndpoint>;
  private readonly _loginRedirectEndpoint = new Map<string, LoginRedirectEndpoint>();
  private readonly _fileUploadApiEndpoint = new Map<string, FileUploadEndpoint>();
  private readonly _tags: Map<string, string | boolean>;

  public get api() {
    return this._endpoints.get('api');
  }
  public get asset() {
    return this._endpoints.get('asset');
  }
  public get dis() {
    return this._disendpoints.get('dis');
  }

  public get engageUserDetail() {
    return this._endpoints.get('engageUserDetail');
  }
  
  public get video() {
    return this._endpoints.get('video');
  }

  public get loginredirect() {
    return this._loginRedirectEndpoint.get('loginredirect');
  }

  public get fileuploadapi() {
    return this._fileUploadApiEndpoint.get('fileuploadapi');
  }

  public getUrl(key: string): Endpoint {
    if (!isEmpty(key)) {
      if (this._endpoints.has(key)) {
        return this._endpoints.get(key);
      }
    }
    return new Endpoint({});
  }

  public getTag(key: string): string | boolean {
    if (!isEmpty(key)) {
      if (this._tags.has(key)) {
        return this._tags.get(key);
      }
    }
    return false;
  }
  //
  // Constructor
  //
  constructor();
  constructor(options: object);
  constructor(options?: any) {
    if (!isNil(options.hostname)) {
      this.hostname = new Endpoint(options.hostname);
    }

    if (!isNil(options.name)) {
      this.name = options.name;
    }
    //
    // Initialize options.endpoint
    //
    if (isNil(options.endpoints)) {
      options['endpoints'] = {};
    }
    if (isNil(options.tags)) {
      options['tags'] = {};
    }
    this._endpoints = new Map<string, Endpoint>();
    this._disendpoints = new Map<string, DISEndpoint>();
    this._loginRedirectEndpoint = new Map<string, LoginRedirectEndpoint>();
    this._fileUploadApiEndpoint = new Map<string, FileUploadEndpoint>();
    this._tags = new Map<string, string | boolean>();
    //
    // Convert endpoints to Endpoint objects.
    //
    Object.keys(options.endpoints).forEach(key => {
      if (key === 'dis') {
        this._disendpoints.set(key, new DISEndpoint(options.endpoints[key]))
      } else if (key === 'loginredirect') {
        this._loginRedirectEndpoint.set(key, new LoginRedirectEndpoint(options.endpoints[key]))
      } else if (key === 'fileuploadapi') {
        this._fileUploadApiEndpoint.set(key, new FileUploadEndpoint(options.endpoints[key]))
      } else {
        this._endpoints.set(key, new Endpoint(options.endpoints[key]))
      }
    });
    Object.keys(options.tags).forEach(key => {
      this._tags.set(key, options.tags[key])
    });
  }
}
